<template>
	<v-layout wrap v-if="showDialog">
		<v-flex xs10 md8 lg6 xl4>
			<div class="text-center">
				<v-dialog
					:id="`${dialogName}-modal-container`"
					persistent
					v-model="showDialog"
					:width="widthDialog"
					:content-class="getDialogClasses"
					v-bind="allProps"
				>
					<v-card class="pa-2">
						<v-card-title class="pl-3 headline lighten-2" primary-title>{{ titleText }}</v-card-title>
						<v-card-text>
							<pui-form-header v-if="componentHeader && modalData.headerPk" showHeader>
								<component :is="componentHeader" :modelPk="modalData.headerPk" />
							</pui-form-header>
							<v-form action ref="validateForm" class="px-4" @submit.prevent>
								<slot name="message" v-bind:modalData="modalData"></slot>
							</v-form>
						</v-card-text>
						<v-card-actions ref="actions">
							<v-btn depressed @click="verDocumentoPDF()">{{ extraButton }}</v-btn>
							<v-spacer></v-spacer>
							<v-btn v-if="!disableCancel" :id="`${dialogName}-btn-cancel`" depressed @click="cancel()" :disabled="loading">{{
								buttonCancel
							}}</v-btn>
							<v-btn
								color="primary"
								:id="`${dialogName}-btn-ok`"
								depressed
								@click="firmarPdf()"
								:loading="loading"
								:disabled="loading"
								>{{ buttonOk }}</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'PuiModalDialogForm',
	props: {
		value: {
			type: Object
		},
		titleText: {
			type: String,
			required: true
		},
		modelName: {
			type: String,
			required: true
		},
		dialogName: {
			type: String,
			required: true
		},
		dialogClasses: {
			type: String,
			default: ''
		},
		overflow: {
			type: Boolean,
			default: true
		},
		okText: {
			type: String
		},
		cancelText: {
			type: String
		},
		disableCancel: {
			type: Boolean,
			default: false
		},
		widthDialog: {
			type: [String, Number],
			default: 'unset'
		},
		onShow: {
			type: Function,
			default: null
		},
		componentHeaderName: {
			type: String
		},
		showDialogProp: {
			type: Boolean,
			default: false
		},
		onOk: {
			type: Function,
			default: null
		},
		onCancel: {
			type: Function,
			default: null
		},
		onExtraButton: {
			type: Function,
			default: null
		},
		extraButton: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			modalData: {},
			componentHeader: null,
			headerPk: {},
			showDialog: false,
			loading: false
		};
	},
	methods: {
		firmarPdf() {
			if (this.disableCancel || (!this.loading && this.$refs.validateForm !== undefined && this.$refs.validateForm.validate())) {
				if (this.onOk) {
					this.loading = true;
					// onOk Function handles show/hide dialog on return value
					const returnOnOk = this.onOk(this.modalData);
					if (returnOnOk !== undefined) {
						returnOnOk.then(value => {
							this.showDialog = !value;
							this.loading = false;
						});
					} else {
						this.loading = false;
					}
				} else {
					if (this.modalData.isAction) {
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
					}
					this.showDialog = false;
				}
			}
		},
		verDocumentoPDF() {
			if (this.disableCancel || (!this.loading && this.$refs.validateForm !== undefined && this.$refs.validateForm.validate())) {
				if (this.onExtraButton) {
					this.loading = true;
					// onOk Function handles show/hide dialog on return value
					const returnonExtraButton = this.onExtraButton(this.modalData);
					if (returnonExtraButton !== undefined) {
						returnonExtraButton.then(value => {
							this.showDialog = !value;
							this.loading = false;
						});
					} else {
						this.loading = false;
					}
				} else {
					if (this.modalData.isAction) {
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
					}
					this.showDialog = false;
				}
			}
		},
		cancel() {
			if (this.onCancel) {
				// onCancel Function handles show/hide dialog on return value
				const returnOnCancel = this.onCancel(this.modalData);
				if (returnOnCancel !== undefined) this.showDialog = !returnOnCancel;
			} else {
				this.showDialog = false;
			}
			if (this.modalData.isAction) {
				this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
			}
			this.modalData = {};
		},
		loaderDynamicHeader() {
			if (this.headerPk && this.componentHeaderName) {
				this.componentHeader = this.componentHeaderName;
			}
		}
	},
	computed: {
		getOverflow() {
			return this.overflow ? '' : 'pui-modal-dialog-overflow-off';
		},
		buttonOk() {
			return this.okText || this.$t('pui9.accept');
		},
		buttonCancel() {
			return this.cancelText || this.$t('pui9.cancel');
		},
		getDialogClasses() {
			return this.dialogClasses + ' ' + this.getOverflow;
		},
		allProps() {
			return { ...this.$attrs, ...this.$props };
		}
	},
	mounted() {
		this.loaderDynamicHeader();
		this.$puiEvents.$on(`pui-modalDialogForm-${this.dialogName}-${this.modelName}-show`, data => {
			// Clean posible oldData
			this.modalData = {};
			for (const key in data) {
				if (Object.prototype.hasOwnProperty.call(data, key)) {
					// For make the container of the data reactive we use $set vue function
					this.$set(this.modalData, key, data[key]);
				}
			}
			// If not loaded from action, disable action events for transfer data, disable styles action button...
			this.modalData.isAction = data.isAction ? data.isAction : false;
			if (data.showDialog === undefined || data.showDialog === true) this.showDialog = true;
			this.onShow && this.onShow(this.modalData);
		});
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialogForm-${this.dialogName}-${this.modelName}-show`);
	},
	watch: {
		// If some data change from the modalData $emit it in case that they need on the parent component
		modalData: {
			handler(newValue) {
				if (newValue.showDialog !== undefined) {
					this.showDialog = newValue.showDialog;
				}
				this.$emit('input', newValue);
			},
			deep: true
		},
		showDialogProp(newValue) {
			this.loading = false;
			this.showDialog = newValue;
		}
	}
};
</script>
